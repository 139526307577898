import axios from "axios";
import { cn } from "@/lib/utils";
import { useState } from "react";

const Waitlist = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({
    status: "",
    message: "",
  });

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
          const response = await axios.post("https://api.opencap.co/waitlist", {
            email,
          });

          setEmail("");
          setResponse({
            status: "success",
            message: "You've been added to the waitlist. 🎉",
          });

          setLoading(false);
        } catch (error) {
          setResponse({
            status: "error",
            message: error.response.data.message,
          });

          setLoading(false);
        }
      }}
    >
      <fieldset className="relative z-50">
        <input
          placeholder="Enter your email"
          type="email"
          id="email"
          autoComplete="email"
          aria-label="Email address"
          required
          className="bg-background font-sm text-primary outline-none py-1 px-3 w-[360px] rounded-lg h-11 border"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        {response && response.message && (
          <p
            className={cn(
              "text-xs mt-2",
              response.status === "success"
                ? "text-emerald-500"
                : "text-red-500",
            )}
          >
            {response.message}
          </p>
        )}

        <button
          type="submit"
          disabled={loading}
          className={cn(
            "absolute right-2 h-7 top-2 px-4 rounded-md font-normal text-sm z-10 text-white",
            loading
              ? "cursor-not-allowed bg-primary/70"
              : "cursor-pointer bg-primary",
          )}
        >
          {loading ? "Submitting ..." : "Join the waitlist"}
        </button>
      </fieldset>
    </form>
  );
};

export default Waitlist;
